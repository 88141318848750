<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="4"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p
                    class="text-2xl font-weight-semibold text--primary mb-2"
                  >
                    Bienvenue sur {{ appName }} !
                  </p>
                  <p class="mb-2">
                    Vous pouvez créer votre compte et commencer l'aventure
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form @submit="onSubmit">
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="Email"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Mot de passe"
                      placeholder="Mot de passe"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                    >
                      Créer mon compte
                    </v-btn>
                  </v-form>
                </v-card-text>

                <v-card-text
                  class="d-flex align-center justify-center flex-wrap mt-2"
                >
                  <span
                    v-show="errorMessage"
                    class="red"
                  >
                    {{ errorMessage }}
                  </span>
                </v-card-text>
                <v-card-text
                  class="d-flex align-center justify-center flex-wrap mt-2"
                >
                  <a href="/login">Revenir à la page de connexion</a>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { ref } from '@vue/composition-api'
import store from '@/store'

// TODO: this view and login view should be refactored together

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const errorMessage = ref(null)
    const onSubmit = e => {
      e.preventDefault()
      const values = {
        email: email.value,
        password: password.value,
      }
      store.dispatch('user/SIGNUP', values).catch(error => {
        switch (error.code) {
          case 'auth/invalid-email':
            errorMessage.value = "L'adresse email est invalide"
            break
          case 'auth/email-already-in-use':
            errorMessage.value = 'Cette adresse email est déjà utilisée'
            break
          case 'auth/weak-password':
            errorMessage.value = 'Le mot de passe est trop faible'
            break
          default:
            errorMessage.value = `Une erreur est survenue (${errorMessage.message}) Réessayez.`
            break
        }
      })
    }

    return {
      isPasswordVisible,
      email,
      password,
      errorMessage,

      // Functions
      onSubmit,

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
.red {
  color: red;
}
</style>
